import React from "react";
import { Container } from "semantic-ui-react";

function VideoPlayer({ playbackUrl, setFeaturedProduct }) {
  React.useEffect(() => {
    if (playbackUrl) {
      const mediaPlayerScript = document.createElement("script");
      mediaPlayerScript.src =
        "https://player.live-video.net/1.3.1/amazon-ivs-player.min.js";
      mediaPlayerScript.async = true;
      mediaPlayerScript.onload = () =>
        mediaPlayerScriptLoaded({ playbackUrl, setFeaturedProduct });
      document.body.appendChild(mediaPlayerScript);
      console.log("Append mediaplayerscript", mediaPlayerScript);
    }
  }, [playbackUrl]);

  return (
      <Container>
        <video
          id="video-player"
          width={600}
          style={{ "object-fit": "fill" }}
          type="application/x-mpegURL"
          playsInline
          controls
        ></video>
      </Container>
  );
}

function mediaPlayerScriptLoaded({ playbackUrl, setFeaturedProduct }) {
  console.log("mediaPlayerScriptLoaded");

  const MediaPlayerPackage = window.IVSPlayer;

  // First, check if the browser supports the Amazon IVS player.
  if (!MediaPlayerPackage.isPlayerSupported) {
    console.warn("The current browser does not support the Amazon IVS player.");
    return;
  }

  const PlayerState = MediaPlayerPackage.PlayerState;
  const PlayerEventType = MediaPlayerPackage.PlayerEventType;

  // Initialize player
  const player = MediaPlayerPackage.create();
  player.attachHTMLVideoElement(document.getElementById("video-player"));

  // Attach event listeners
  player.addEventListener(PlayerState.PLAYING, () => {
    console.log("Player State - PLAYING");
  });
  player.addEventListener(PlayerState.ENDED, () => {
    console.log("Player State - ENDED");
  });
  player.addEventListener(PlayerState.READY, () => {
    console.log("Player State - READY");
  });
  player.addEventListener(PlayerEventType.ERROR, (err) => {
    console.warn("Player Event - ERROR:", err);
  });
  player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
    console.log("Timed metadata: ", cue.text);
    const metadataRaw = JSON.parse(cue.text).metadata;
    setFeaturedProduct(JSON.parse(metadataRaw));
  });

  // Setup stream and play
  player.setAutoplay(true);
  player.setMuted(false);
  player.setVolume(1);
  player.load(playbackUrl);
}

export default VideoPlayer;
