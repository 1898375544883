import React from "react";
import {
  Container,
  Divider,
  Segment,
  Form,
  Grid,
  Button,
  Table,
} from "semantic-ui-react";
import { PageContext, PAGE } from "../PageContext";
import AppAuthenticator from "../components/AppAuthenticator";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createEvent, createChannel } from "../graphql/mutations";
import { eventsByOwnerAndCreatedAt } from "../graphql/queries";
import { useHistory } from "react-router-dom";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";

function NewEventPage() {
  const pageContext = React.useContext(PageContext);
  const user = pageContext.user;
  const userId = pageContext.getUserId();

  const history = useHistory();
  const [eventName, setEventName] = React.useState("");
  const [shouldFetchMoreEvents, setShouldFetchMoreEvents] = React.useState(0);
  const [yourEvents, setYourEvents] = React.useState([]);
  const [eventCode, setEventCode] = React.useState();

  React.useEffect(() => {
    pageContext.setActivePage(PAGE.NEW_EVENT.key);
  }, []);

  React.useEffect(() => {
    if (userId) {
      fetchYourEvents({
        userId: pageContext.getUserId(),
        setYourEvents,
      });
    }
  }, [userId, shouldFetchMoreEvents]);

  if (user) {
    return (
      <Container>
        <Segment placeholder>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Row>
              <Grid.Column>
                {CreateEventForm({
                  eventName,
                  setEventName,
                  shouldFetchMoreEvents,
                  setShouldFetchMoreEvents,
                })}
              </Grid.Column>
              <Grid.Column>
                {ResumeEventForm({ eventCode, setEventCode, history })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider vertical>Or</Divider>
        </Segment>
        {EventsTable(yourEvents)}
      </Container>
    );
  } else {
    return <AppAuthenticator />;
  }
}

async function fetchYourEvents({ userId, setYourEvents }) {
  true && console.log("fetchYourEvents", { userId });

  const response = await API.graphql(
    graphqlOperation(eventsByOwnerAndCreatedAt, {
      owner: userId,
      sortDirection: "DESC",
      limit: 20,
    })
  );
  const events = response.data.eventsByOwnerAndCreatedAt.items;
  true && console.log("fetchYourEvents", { response, events });

  setYourEvents(events);
}

function CreateEventForm({
  eventName,
  setEventName,
  shouldFetchMoreEvents,
  setShouldFetchMoreEvents,
}) {
  async function handleCreateNewEvent() {
    true && console.log("CreateEventForm", "handleCreateNewEvent", eventName);
    const generatedCode = uniqueNamesGenerator({
      dictionaries: [adjectives, colors, animals],
      separator: "-",
    });

    try {
      const ivsResponseRaw = await API.graphql(
        graphqlOperation(createChannel, {
          name: eventName + "-" + generatedCode,
        })
      );
      console.log("ivsResponseRaw", ivsResponseRaw);
      const ivsResponse = JSON.parse(ivsResponseRaw.data.createChannel);
      console.log("ivsResponse", ivsResponse);

      const createEventInput = {
        name: eventName,
        code: generatedCode,
        channelArn: ivsResponse.channel.arn,
        streamUrl: ivsResponse.channel.playbackUrl,
        ingestUrl:
          "rtmps://" + ivsResponse.channel.ingestEndpoint + ":443/app/",
        streamKey: ivsResponse.streamKey.value,
      };
      console.log("createEventInput", createEventInput);
      const response = await API.graphql(
        graphqlOperation(createEvent, { input: createEventInput })
      );
      true &&
        console.log("handleCreateNewEvent Response", JSON.stringify(response));

      setEventName("");
      setShouldFetchMoreEvents(shouldFetchMoreEvents + 1);
    } catch (error) {
      console.error("Failed to create event", error);
    }
  }

  return (
    <Form onSubmit={handleCreateNewEvent}>
      <Form.Input
        icon="user"
        iconPosition="left"
        label="Create New Event"
        placeholder="Event Name"
        value={eventName}
        onChange={(e, { name, value }) => setEventName(value)}
      />
      <Button fluid positive type="submit" content="Create" />
    </Form>
  );
}

function ResumeEventForm({ eventCode, setEventCode, history }) {
  function handleResumeEvent() {
    history.push("/hostevent/" + eventCode);
  }

  return (
    <Form onSubmit={handleResumeEvent}>
      <Form.Input
        icon="barcode"
        iconPosition="left"
        label="Resume Event"
        placeholder="Event Code"
        value={eventCode}
        onChange={(e, { name, value }) => setEventCode(value)}
      />
      <Button fluid positive type="submit" content="Resume" />
    </Form>
  );
}

function EventsTable(events) {
  true && console.log("EventsComponent", { events });
  const eventRows = events.map((event) => {
    return (
      <Table.Row key={event.id}>
        <Table.Cell>{event.name}</Table.Cell>
        <Table.Cell>{event.code}</Table.Cell>
        <Table.Cell>
          {JSON.stringify({
            channelArn: event.channelArn,
            ingestUrl: event.ingestUrl,
            streamKey: event.streamKey,
            streamUrl: event.streamUrl,
          })}
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Code</Table.HeaderCell>
          <Table.HeaderCell>Channel</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{eventRows}</Table.Body>
    </Table>
  );
}

export default NewEventPage;
