import React from 'react';
import { Grid, Segment, Menu } from 'semantic-ui-react'
import { Route, Link, Switch, Redirect, useHistory } from 'react-router-dom'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import HomePage from './pages/HomePage';
import HostEventPage from './pages/HostEventPage';
import JoinEventPage from './pages/JoinEventPage';
import NewEventPage from './pages/NewEventPage';
import WatchEventPage from './pages/WatchEventPage';
import { PageContext, PAGE } from './PageContext';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsconfig);

function App() {
  const pageContext = React.useContext(PageContext)
  const history = useHistory();

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      pageContext.setAuthState(nextAuthState);

      if (nextAuthState === AuthState.SignedIn) {
        // User Signed In
        console.log("User signed in", authData);
        pageContext.setUser(authData);
      }
      if (nextAuthState === AuthState.SignedOut) {
        // User Signed Out
        console.log("User signed out", nextAuthState);
        history.push(PAGE.HOME.path);
        pageContext.setActivePage(PAGE.HOME.key);
        pageContext.setUser(null);
      }
    });
  }, []);

  function RenderMenusIfSignedIn(authState, user) {
    if (authState === AuthState.SignedIn && user) {
      function RenderEmailIfExists(user) {
        if (user && user.attributes && user.attributes.email) {
          return <Menu.Item>Hello, {user.attributes.email}</Menu.Item>
        }
      }
      return (
        <Menu.Menu borderless='true' position='right'>
          { RenderEmailIfExists(user)}
          <Menu.Item><AmplifySignOut/></Menu.Item>
        </Menu.Menu>
      )
    }
  }

  return (
    <Segment>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Menu pointing>
              <Menu.Item header>Octank Auction</Menu.Item>
              <Menu.Item
                as={Link}
                to={PAGE.HOME.path}
                name="Home"
                active={pageContext.activePage === PAGE.HOME.key}
                onClick={() => pageContext.setActivePage(PAGE.HOME.key)} />
              <Menu.Item
                as={Link}
                to={PAGE.NEW_EVENT.path}
                name="Host An Auction"
                active={[PAGE.NEW_EVENT.key, PAGE.HOST_EVENT.key].includes(pageContext.activePage)}
                onClick={() => pageContext.setActivePage(PAGE.NEW_EVENT.key)} />
              <Menu.Item
                as={Link}
                to={PAGE.JOIN_EVENT.path}
                name="Watch An Auction"
                active={[PAGE.JOIN_EVENT.key, PAGE.WATCH_EVENT.key].includes(pageContext.activePage)}
                onClick={() => pageContext.setActivePage(PAGE.JOIN_EVENT.key)} />
              {RenderMenusIfSignedIn(pageContext.authState, pageContext.user)}
            </Menu>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Switch>
              <Route
                path={PAGE.HOME.path} exact
                component={HomePage} />
              <Route
                path={PAGE.HOST_EVENT.path + "/:code"} exact
                component={HostEventPage} />
              <Route
                path={PAGE.NEW_EVENT.path} exact
                component={NewEventPage} />
              <Route
                path={PAGE.JOIN_EVENT.path} exact
                component={JoinEventPage} />
              <Route
                path={PAGE.WATCH_EVENT.path + "/:code"} exact
                component={WatchEventPage} />
              <Redirect path='*' to='/' />
            </Switch>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export default App;
