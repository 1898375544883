import React from "react";
import { Container, Message, Icon, Grid, Divider } from "semantic-ui-react";
import { PageContext, PAGE } from "../PageContext";
import AppAuthenticator from "../components/AppAuthenticator";
import Messenger from "./HostEvent/Messenger";
import { eventsByCode } from "../graphql/queries";
import { useParams } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import Products from "../components/Products";
import VideoPlayer from "../components/VideoPlayer";

function WatchEventPage() {
  const { code } = useParams();

  const pageContext = React.useContext(PageContext);
  const user = pageContext.user;

  const [event, setEvent] = React.useState();
  const [featuredProduct, setFeaturedProduct] = React.useState();

  React.useEffect(() => {
    pageContext.setActivePage(PAGE.WATCH_EVENT.key);
  }, []);

  React.useEffect(() => {
    fetchEvent({ eventCode: code, setEvent });
  }, []);

  const FeaturedProduct = () => {
    if (featuredProduct) {
      console.log("FeaturedProduct", featuredProduct);
      console.log("FeaturedProduct imageUrl", featuredProduct.imageUrl);
      return (
        <div>
          <h2>Now Featuring:</h2>
          <Products products={[featuredProduct]}/>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  if (user && event) {
    console.log("WatchEvent", user, event);

    return (
      <Container>
        <Message icon info>
          <Icon name="youtube play" />
          <Message.Content>
            <Message.Header>
              You are now watching auction with event code: {event.code}
            </Message.Header>
          </Message.Content>
        </Message>
        <Grid fluid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Grid.Row>
                <Grid.Column>
                  <h2>Your Host</h2>
                  <VideoPlayer
                    playbackUrl={event.streamUrl}
                    setFeaturedProduct={setFeaturedProduct}
                  />
                </Grid.Column>
                <Divider section />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={6}>
              <Grid.Row>
                <FeaturedProduct />
                { featuredProduct ? <Divider section /> : null }
              </Grid.Row>
              <h2>Chat With Your Host</h2>
              <Messenger eventId={event.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  } else {
    true && console.log("WatchEvent", "Not Ready", user, event);
    return <AppAuthenticator />;
  }
}

async function fetchEvent({ eventCode, setEvent }) {
  const queryParams = {
    code: eventCode,
  };
  const response = await API.graphql(
    graphqlOperation(eventsByCode, queryParams)
  );
  const events = response.data.eventsByCode.items;
  const event = events[0];
  setEvent(event);
}

export default WatchEventPage;
