/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "octanklivedemo-productionhosting-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://du01mj44yl6w4.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:e604ff59-8e22-4926-9316-0389dff8855e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1znfYA34B",
    "aws_user_pools_web_client_id": "5gonhvmgdbntb8d0gv6auqht8f",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://yim44zrclbhr3k5nzopj3fdmpe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
