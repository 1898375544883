import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { PageContext, PAGE } from "../PageContext";
import AppAuthenticator from "../components/AppAuthenticator";
import { useHistory } from "react-router-dom";

function JoinEventPage() {
  const pageContext = React.useContext(PageContext);
  const history = useHistory();
  const [eventCode, setEventCode] = React.useState();

  React.useEffect(() => {
    pageContext.setActivePage(PAGE.JOIN_EVENT.key);
  }, []);

  function handleJoinEvent() {
    history.push("/watchevent/" + eventCode);
  }

  if (pageContext.user) {
    return (
      <Segment placeholder>
        <Form onSubmit={handleJoinEvent}>
          <Form.Input
            icon="barcode"
            iconPosition="left"
            label="Event Code"
            placeholder="code"
            value={eventCode}
            onChange={(e, { name, value }) => setEventCode(value)}
          />
          <Button
            fluid
            positive
            type="submit"
            style={{ marginTop: "8px" }}
            content="Join"
          />
        </Form>
      </Segment>
    );
  } else {
    return <AppAuthenticator />;
  }
}

export default JoinEventPage;
