import React from "react";
import { Divider, Button, Card, Grid, Image, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PageContext, PAGE } from '../PageContext';

function HomePage() {
    const pageContext = React.useContext(PageContext);

    React.useEffect(() => {
        pageContext.setActivePage(PAGE.HOME.key);
    }, [])

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={6}>
                    <h2>Get Started</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus libero, pharetra ut condimentum at, iaculis et sapien. Donec ut posuere sapien. Donec a ipsum quis orci varius ultrices. Proin id nulla vel sem suscipit imperdiet quis eget ex. Vivamus id luctus mi, eget luctus diam. Sed a efficitur nisi, pellentesque aliquet nisi. Suspendisse eleifend, nisi id congue viverra, nulla ante mollis enim, ac cursus elit lorem quis metus. Donec sit amet pulvinar enim. Vivamus lobortis arcu ut nulla porta, in placerat nulla feugiat. Aenean ac odio cursus lectus imperdiet gravida vel eget mi.</p>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Image fluid centered rounded
                        src='https://d2908q01vomqb2.cloudfront.net/da4b9237bacccdf19c0760cab7aec4a8359010b0/2020/07/14/8.jpg' />
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column width={8}>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>Host An Auction</Card.Header>
                            <Card.Meta>Influencers: Earn money. Share products you love</Card.Meta>
                            <Card.Description>
                                <Image src='https://images.unsplash.com/photo-1598743400863-0201c7e1445b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80' />
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button as={Link} to='/newevent' basic color='green' fluid>
                                Host
                            </Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>Join An Auction</Card.Header>
                            <Card.Meta>Fans: Discover products from your favorite people</Card.Meta>
                            <Card.Description>
                                <Image src='https://images.pexels.com/photos/1587927/pexels-photo-1587927.jpeg?cs=srgb&dl=pexels-mark-angelo-1587927.jpg&fm=jpg' />
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button as={Link} to='/joinevent' basic color='green' fluid>
                                Join
                            </Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default HomePage;