/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMessageByMessageId = /* GraphQL */ `
  subscription OnMessageByMessageId($eventMessagesId: ID!) {
    onMessageByMessageId(eventMessagesId: $eventMessagesId) {
      id
      owner
      createdAt
      content
      eventMessagesId
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      owner
      createdAt
      name
      code
      channelArn
      streamUrl
      ingestUrl
      streamKey
      messages {
        items {
          id
          owner
          createdAt
          content
          eventMessagesId
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      owner
      createdAt
      name
      code
      channelArn
      streamUrl
      ingestUrl
      streamKey
      messages {
        items {
          id
          owner
          createdAt
          content
          eventMessagesId
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      owner
      createdAt
      name
      code
      channelArn
      streamUrl
      ingestUrl
      streamKey
      messages {
        items {
          id
          owner
          createdAt
          content
          eventMessagesId
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      owner
      createdAt
      content
      eventMessagesId
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      owner
      createdAt
      content
      eventMessagesId
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      owner
      createdAt
      content
      eventMessagesId
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
