import React from "react";
import {
  Container,
  Segment,
  Feed,
  Form,
  Input,
  Image,
} from "semantic-ui-react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createMessage } from "../../graphql/mutations";
import { onMessageByMessageId } from "../../graphql/subscriptions";

function Messenger({ eventId }) {
  const [messages, setMessages] = React.useState([]);
  const [messageContent, setMessageContent] = React.useState("");
  const messagesEndRef = React.useRef(null);

  React.useEffect(() => {
    if (eventId) {
      const unsubscribe = subscribeToNewMessages({
        eventId,
        messages,
        setMessages,
        messagesEndRef
      });
      return unsubscribe;
    }
  }, [eventId]);

  return (
    <Container>
      <Segment style={{ overflow: "auto", minHeight: "10em", maxHeight: "27em" }}>
        <Feed>
          <Messages messages={messages} />
          <div ref={messagesEndRef}></div>
        </Feed>
      </Segment>
      <Form
        onSubmit={() =>
          handleCreateMessage({
            eventId,
            messageContent,
            setMessageContent,
            messagesEndRef,
          })
        }
      >
        <Form.Group>
          <Form.Field width={12}>
            <Input
              fluid
              icon="chat"
              iconPosition="left"
              placeholder="Say something..."
              value={messageContent}
              onChange={(e, { name, value }) => setMessageContent(value)}
            />
          </Form.Field>
          <Form.Button fluid primary width={4} content="Send" />
        </Form.Group>
      </Form>
    </Container>
  );
}

function subscribeToNewMessages({ eventId, messages, setMessages, messagesEndRef }) {
  const subscriber = API.graphql(
    graphqlOperation(onMessageByMessageId, { eventMessagesId: eventId })
  ).subscribe({
    next: (data) => {
      true && console.log("messages", messages);

      const newMessage = data.value.data.onMessageByMessageId;
      true && console.log("Received new message", newMessage);

      setMessages((oldState) => [...oldState, newMessage]);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    },
  });
  return () => subscriber.unsubscribe();
}

async function handleCreateMessage({
  eventId,
  messageContent,
  setMessageContent,
  messagesEndRef,
}) {
  const input = {
    content: messageContent,
    eventMessagesId: eventId,
  };
  true && console.log("handleCreateMessage Request", input);

  const response = await API.graphql(
    graphqlOperation(createMessage, { input })
  );
  true && console.log("handleCreateMessage Response", response);

  setMessageContent("");
}

function Messages({ messages }) {
  const component = messages.map((message) => {
    return (
      <Feed.Event key={message.id}>
        <Feed.Label>
          <Image src="https://react.semantic-ui.com/images/avatar/small/jenny.jpg" />
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary>
            {message.owner}: {message.content}
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );
  });
  return component;
}

export default Messenger;
