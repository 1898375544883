import React from "react";
import { Message, Icon } from "semantic-ui-react";

function Instructions({ ingestUrl, streamKey, eventCode }) {
  const [visible, setVisible] = React.useState(true);

  if (visible && ingestUrl && streamKey && eventCode) {
    return (
      <Message icon info onDismiss={() => setVisible(false)}>
        <Icon name="youtube play" />
        <Message.Content>
          <Message.Header>You Are Live!</Message.Header>
          <Message.List>
            <Message.Item>
              Stream your video feed to this url: {ingestUrl}
            </Message.Item>
            <Message.Item>
              Use this stream key: {streamKey}
            </Message.Item>
            <Message.Item>
              Share this event code with your fans: {eventCode}
            </Message.Item>
          </Message.List>
        </Message.Content>
      </Message>
    );
  }
}

export default Instructions;
