import React from "react";
import { useParams } from "react-router-dom";
import { PageContext } from "../PageContext";
import AppAuthenticator from "../components/AppAuthenticator";
import { Container, Grid, Divider } from "semantic-ui-react";
import { eventsByCode } from "../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";
import Instructions from "./HostEvent/Instructions";
import Messenger from "./HostEvent/Messenger";
import { PAGE } from "../PageContext";
import ProductSearch from "../components/ProductSearch";
import Products from "../components/Products";
import VideoPlayer from "../components/VideoPlayer";

function HostEvent() {
  const { code } = useParams();

  const pageContext = React.useContext(PageContext);
  const user = pageContext.user;
  const userId = pageContext.getUserId();

  const [event, setEvent] = React.useState();
  const [featuredProduct, setFeaturedProduct] = React.useState(null);

  React.useEffect(() => {
    pageContext.setActivePage(PAGE.HOST_EVENT.key);
  }, []);

  React.useEffect(() => {
    if (userId) {
      setEventByOwnerAndCode(
        {
          userId: pageContext.getUserId(),
          code,
        },
        setEvent
      );
    }
  }, [userId]);

  const FeaturedProduct = () => {
    if (featuredProduct) {
      console.log("FeaturedProduct", featuredProduct);
      console.log("FeaturedProduct imageUrl", featuredProduct.imageUrl);
      return (
        <div>
          <h2>Now Featuring:</h2>
          <Products products={[featuredProduct]}/>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  if (user && event) {
    console.log("HostEvent", user.attributes);

    return (
      <Container>
        <Instructions
          ingestUrl={event.ingestUrl}
          streamKey={event.streamKey}
          eventCode={event.code}
        />
        <Grid fluid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Grid.Row>
                <Grid.Column>
                  <h2>Stream Your Video</h2>
                  <VideoPlayer
                    playbackUrl={event.streamUrl}
                    setFeaturedProduct={setFeaturedProduct}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h2>Search For A Product</h2>
                  <ProductSearch channelArn={event.channelArn} />
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={6}>
              <Grid.Row>
                <FeaturedProduct />
                { featuredProduct ? <Divider section /> : null }
              </Grid.Row>
              <h2>Chat With Your Fans</h2>
              <Messenger eventId={event.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  } else {
    true && console.log("HostEvent", "Not Ready", { user, event });
    return <AppAuthenticator />;
  }
}

async function setEventByOwnerAndCode({ userId, code }, setEvent) {
  const fetchedEvent = await fetchEventByOwnerAndCode({ userId, code });
  if (fetchedEvent) {
    true && console.log("fetchedEvent", "setEvent", { fetchedEvent });
    setEvent(fetchedEvent);
  }
}

async function fetchEventByOwnerAndCode({ userId, code }) {
  true &&
    console.log("fetchEventByOwnerAndCode", {
      userId,
      code,
    });
  const queryParams = {
    owner: userId,
    code: code,
  };
  const response = await API.graphql(
    graphqlOperation(eventsByCode, queryParams)
  );
  const events = response.data.eventsByCode.items;
  true &&
    console.log("fetchEventByOwnerAndCode", "events", {
      events,
      firstEvent: events[0],
    });
  return events[0];
}

export default HostEvent;
