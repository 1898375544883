import React from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { searchProducts } from "../graphql/queries";
import { Container, Form, Button, Segment } from "semantic-ui-react";
import Products from "./Products";

function ProductSearch({ channelArn }) {
  const [query, setQuery] = React.useState("");
  const [products, setProducts] = React.useState([]);

  async function handleQuery() {
    console.log("Searching for", query);
    const response = await API.graphql(
      graphqlOperation(searchProducts, {
        filter: {
          or: [
            { name: { matchPhrase: query } },
            { description: { matchPhrase: query } },
          ],
        },
      })
    );
    console.log("query response", response);
    const fetchedProducts = response.data.searchProducts.items;
    console.log("fetched products", fetchedProducts);
    setProducts(fetchedProducts);
  }

  return (
    <Container>
      <Form onSubmit={handleQuery}>
        <Form.Group>
          <Form.Input
            width={10}
            icon="search"
            iconPosition="left"
            placeholder="Product Name"
            value={query}
            onChange={(e, { name, value }) => setQuery(value)}
          />
          <Button width={6} primary type="submit" content="Search" />
        </Form.Group>
      </Form>
      <Segment
        style={{ overflow: "auto", minHeight: "10em", maxHeight: "27em" }}
      >
        <Products products={products} channelArn={channelArn} isInteractive={true} />
      </Segment>
    </Container>
  );
}

export default ProductSearch;