import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';

function AppAuthenticator() {
    return (
        <AmplifyAuthenticator usernameAlias='email' initialAuthState='signup'>
            <AmplifySignUp
                slot='sign-up'
                usernameAlias='email'
                headerText='Sign up'
                formFields={[
                    {
                        type: 'email',
                        required: true
                    },
                    {
                        type: 'password',
                        required: true
                    }]}
            />
            <AmplifySignIn
                slot='sign-in'
                usernameAlias='email'
                headerText='Sign in'
                formFields={[
                    {
                        type: 'email',
                        required: true
                    },
                    {
                        type: 'password',
                        required: true
                    }]}
            />
        </AmplifyAuthenticator>
    )
}

export default AppAuthenticator;
