import React from "react";
import { Button, Item, Icon } from "semantic-ui-react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { putMetadata } from "../graphql/mutations";

function Products({ products, channelArn, isInteractive }) {
  const [nowShowingProductId, setNowShowingProductId] = React.useState(null);

  const onClickHandler = async ({ event, product }) => {
    event.preventDefault();

    console.log("Show To Fans", {
      channelArn,
      product,
    });

    const ivsResponseRaw = await API.graphql(
      graphqlOperation(putMetadata, {
        channelArn,
        metadata: JSON.stringify(product),
      })
    );
    console.log("ivsResponseRaw", ivsResponseRaw);

    setNowShowingProductId(product.id);
  };

  const productsComponent = products.map((product) => {
    const CallToAction = () => {
      if (product.id == nowShowingProductId) {
        return (
          <Button compact color="red" floated="right">
            Now Showing
          </Button>
        );
      } else if (isInteractive) {
        return (
          <Button
            compact
            color="teal"
            floated="right"
            onClick={(e) => onClickHandler({ event: e, product })}
          >
            Show To Fans
            <Icon name="right chevron" />
          </Button>
        );
      } else {
        return null;
      }
    };

    return (
      <Item key={product.id}>
        <Item.Image
          size="tiny"
          src={
            product.imageUrl ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
        <Item.Content>
          <Item.Header>{product.name}</Item.Header>
          <Item.Description>{product.description}</Item.Description>
          <Item.Extra>
            <CallToAction />
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  });
  return <Item.Group>{productsComponent}</Item.Group>;
}

export default Products;
