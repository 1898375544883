import React from 'react';

const PAGE = {
    'HOME': { key: 'home', path: '/' },
    'NEW_EVENT': { key: 'new-event', path: '/newevent' },
    'JOIN_EVENT': { key: 'join-event', path: '/joinevent' },
    'HOST_EVENT': { key: 'host-event', path: '/hostevent' },
    'WATCH_EVENT': { key: 'watch-event', path: '/watchevent' }
};

const PageContext = React.createContext();

const PageProvider = function(props) {
    const [activePage, setActivePage] = React.useState('home');
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    function getUserId() {
        return extractUserId(user);
    }

    const providerValue = {
        activePage,
        setActivePage,
        authState,
        setAuthState,
        user,
        setUser,
        getUserId
    };

    return <PageContext.Provider value={providerValue}>
        { props.children }
    </PageContext.Provider>
}

function extractUserId(user) {
    return user && user.attributes && user.attributes.sub;
}

export { PageProvider, PageContext, PAGE }